<template>
  <div class="home-container">
    <h3 class="bold-text mb-3" style="text-transform: uppercase;">FirmenzugehörigKEit</h3>
    <p class="home-desc text-color-65-gray">Bitte wählen Sie hier aus, im Auftrag welcher Firma Sie die Katalogwand nutzen möchten. Sie können die Einstellung jederzeit unter dem Menüpunkt PROFIL ändern.</p>
    <loading v-if="loading" />
    <div v-else class="company-container">
      <div v-for="(company, index) in companies" :key="index" class="d-flex flex-column mb-3" :class="{ 'line': index }">
        <div class="d-flex w-100 justify-content-between" :class="index ? 'mt-4': 'mt-2'">
          <span class="bold-text ms-2"> {{company.companyId}} - {{ `${company.zr_nummer}${company.zr_nummer?' -':''}` }} {{ company.name }}</span>
          <button class="btn btn-primary" @click="onLogin(company.companyId)">Auswählen</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import loading from '@/components/common/Loading'
import { mapMutations } from 'vuex'
export default {
  name: 'Home',
  components: {
    loading
  },
  data () {
    return {
      loading: true,
      companies: []
    }
  },
  methods: {
    ...mapMutations(['SET_USER_ROLE', 'SET_ACCESSTOKEN', 'SET_COMPANIES']),
    async onLogin (id) {
      this.loading = true
      try {
        const response = await this.$api.selectAccount(id)
        if (response.data.status === 'success') {
          try {
            const roles = response.data.data.roles
            localStorage.setItem('companyId', id)
            localStorage.setItem('token_access', response.data.data.accessToken)
            this.SET_ACCESSTOKEN(response.data.data.accessToken)
            for (const [key, value] of Object.entries(roles)) {
              if (value) {
                this.SET_USER_ROLE(key)
              }
            }
            this.$router.push('/seller')
          } catch (e) {
            console.error(e)
          }
        }
      } catch (e) {
        this.onTokenError(e)
      }
    },
    onTokenError (e) {
      const isUnauthorizedError = e?.response?.status === 401 || e?.response?.status === 403
      if (isUnauthorizedError) {
        location.reload()
      }
    }
  },
  async created () {
    try {
      const response = await this.$api.getCompanies()
      if (response.data.status === 'success') {
        this.loading = false
        this.companies = response.data.data.companies
        this.SET_COMPANIES(this.companies)
        if (this.companies && this.companies.length === 1) {
          this.onLogin(this.companies[0].companyId)
        }
      }
    } catch (e) {
      this.onTokenError(e)
    }
  }
}
</script>

<style scoped>
.home-container {
  padding: 6em;
  display: flex;
  flex-direction: column;
  align-items: center
}
.home-desc{
  max-width: 620px;
  text-align: center;
  margin-bottom: 2em;
}
.company-container {
  padding: 1em;
  min-width: 800px;
  background-color: #fff;
  box-shadow: 0px 5px 10px rgb(0 0 0 / 10%);
  border-radius: 10px;
}
.company-container .line {
  border-top: 1px solid #F0F0F0;
}
.bold-text {
  font-weight: bolder;
  color: #555555;
}
</style>
